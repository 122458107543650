import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export function textField(theme: Theme) {
  return {
    // HELPER
    MuiFormHelperText: {
      styleOverrides: {
        root: {},
      },
    },

    // LABEL
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 0,
        },
      },
    },

    // BASE
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
        input: {},
      },
    },

    // STANDARD
    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },

    // OUTLINED
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          backgroundColor: theme.palette.grey[100],
          borderColor: "transparent",
        },
        notchedOutline: {
          border: "none",
        },
        input: {
          height: "48px",
          padding: "0px 16px",
        },
      },
    },

    // FILLED
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          backgroundColor: "white",
          borderBottom: "none", // Moved into &:hover

          "&::before, &::after": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "none",
          },
          "&.Mui-focused:after": {
            borderBottom: "none",
          },
        },
        input: {
          height: "48px",
          padding: "0px 16px",
        },
      },
    },
  };
}
