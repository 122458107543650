import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Iconify from "src/components/iconify";
import { useTheme } from "@mui/material";
import KakaoButton from "src/assets/images/kakaoButton.png";
import logo from "src/logo/logo.png";

// ----------------------------------------------------------------------

export default function Footer() {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        position: "relative",
        bgcolor: "background.default",
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,

          textAlign: { md: "unset" },
        }}
      >
        <Box component="img" src={logo} width={100}></Box>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
          justifyContent={{
            md: "space-between",
          }}
        >
          <Grid xs={8} spacing={2} sx={{ mt: "18px" }}>
            <Stack direction="row" spacing={2} sx={{ mb: "32px" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  개인정보처리방침
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  이용약관
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  고객센터
                </Typography>
            </Stack>

            <Stack direction="row" spacing={1}>
              <Typography variant="caption">
                상호명: 펀핀24(FUNPIN24)
              </Typography>
              <Typography variant="body2">대표자: 고영수</Typography>
              <Typography variant="body2">
                사업자등록번호: 129-93-13619
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2">
                통신판매업신고번호: 제 2021-고양덕양구-1769호
              </Typography>
              <Typography variant="body2">
                주소: 경기도 고양시 덕양구 화신로 106
              </Typography>
              <Typography variant="body2">대표번호: 010-5814-0989</Typography>
            </Stack>
            <Typography variant="body2" sx={{ mt: "32px" }}>
              Ⓒ FUNPIN24. All Rights Reserved.
            </Typography>
          </Grid>
          <Grid spacing={2} sx={{ mt: "18px" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold", mt: "16px" }}>
              고객센터
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              color={theme.palette.primary.main}
              sx={{ mt: "32px" }}
            >
              <Iconify icon={"ic:baseline-phone"} />
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                010-5814-0989
              </Typography>
            </Stack>
            <Typography variant="body2">
              운영시간: 24시간 (365일 연중무휴)
            </Typography>
            <Box
              component="img"
              src={KakaoButton}
              width={200}
              sx={{ display: "block" }}
            ></Box>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
