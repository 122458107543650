import axios from 'axios';
import { InternalAxiosRequestConfig, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

const setAccessTokenOnHeader = (config: InternalAxiosRequestConfig) => {
    const tokens = localStorage.getItem('auth');

    if (!tokens) {
        config.headers.Authorization = null;
        return config;
    }
    const { jwt } = JSON.parse(tokens);
    config.headers.Authorization = `Bearer ${jwt}`;
    return config;
};

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://cnitsstudio-api.com',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

instance.interceptors.request.use(setAccessTokenOnHeader, (error) => Promise.reject(error));

instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        if (!axios.isAxiosError(error)) {
            return Promise.reject(error);
        }

        const { config, response } = error;

        if (response?.status === 401 && config?.url === "/auth/token/refresh") {
            window.location.href = "/login";
            return Promise.reject(error);
        }

        if (response?.status === 401 && config) {
            try {
                const token = localStorage.getItem("auth");
                if (!token) {
                    window.location.href = "/login";
                    return;
                }
                const { refresh } = JSON.parse(token);
                const res = await instance.post("/auth/token/refresh", { refresh });
                const { access } = res.data;
                localStorage.setItem("auth", JSON.stringify({ refresh, access }));
                config.headers.Authorization = `Bearer ${access}`;
                return await instance(config as AxiosRequestConfig);
            } catch (refreshError) {
                localStorage.removeItem("auth");
                window.location.href = "/login";
                return Promise.reject(refreshError);
            }
        }

        if (response?.status === 403 && config) {
            // Authorization 헤더 제거
            delete config.headers.Authorization;

            try {
                // 헤더 없이 다시 요청 시도
                const newResponse = await axios(config as AxiosRequestConfig);
                return newResponse;
            } catch (retryError) {
                return Promise.reject(retryError);
            }
        }

        return Promise.reject(error);
    }
);

export { instance };
