import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export function dialog(theme: Theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: "40px 8px 8px 8px",
          textAlign: "center",
          borderRadius: 12,
          width: "350px",
        },
        paperFullScreen: {},
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          fontWeight: 700,
          padding: 0,
        },
      },
    },
  };
}
