import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {getMyAccountInfo, getMyOrderList} from "./url";

export const useGetMyAccountInfo = () => {
    return useQuery({queryKey: ['account-info'], queryFn: getMyAccountInfo});
}

export const useGetMyOrderList = () => {
    return useQuery({queryKey: ['order-list'], queryFn: getMyOrderList});
}