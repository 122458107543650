import { Navigate, useRoutes } from "react-router-dom";

import MainLayout from "src/layouts/main";

import { authRoutes } from "./auth";
import { customerRoutes } from "./customer";
import { myPageRoutes } from "./mypage";
import { productRoutes } from "./product";
import { cartRoutes } from "./cart";
import { lazy } from "react";

export const HomePage = lazy(() => import("src/pages/home"));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },

    ...authRoutes,
    ...customerRoutes,
    ...myPageRoutes,
    ...productRoutes,
    ...cartRoutes,
  ]);
}
