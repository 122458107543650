import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Badge, Divider } from "@mui/material";
import { useRouter } from "../../routes/hooks";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import AdbIcon from "@mui/icons-material/Adb";
import Iconify from "../../components/iconify";
import IconButton from "@mui/material/IconButton";
import logo from "src/logo/logo.png";
import NavIcon from "../../components/nav-icon/nav-icon";
import menu_1 from "../../assets/menus/menu_1.png";
import menu_2 from "../../assets/menus/menu_2.png";
import menu_3 from "../../assets/menus/menu_3.png";
import menu_4 from "../../assets/menus/menu_4.png";
import menu_5 from "../../assets/menus/menu_5.png";
import menu_6 from "../../assets/menus/menu_6.png";
import Stack from "@mui/material/Stack";
import BadgeIcon from "../../components/badge/badge";
import { startTransition, useState, useEffect } from "react";
import { paths } from "../../routes/paths";
import { useLocation } from "react-router-dom";
import {useGetMyAccountInfo} from "../../apis/my-page/quries";
import {useGetCheckOutList} from "../../apis/checkout/quries";
import {useSetRecoilState} from "recoil";
import {CheckoutListState} from "../../state/inquiry";

const pages = ["로그아웃", "마이페이지"];
const settings = [
  { title: "주문조회", path: "/order" },
  { title: "내 정보", path: "/mypage" },
];

export default function Header() {
  const router = useRouter();
  const location = useLocation();
  const profileState = JSON.parse(localStorage.getItem('auth') as string);
  const {error, data} = useGetMyAccountInfo();
  const {error : checkoutError, data : checkout} = useGetCheckOutList();
  const setCheckOutList = useSetRecoilState(CheckoutListState);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [nav, setNav] = useState("");

  useEffect(() => {
    let currentNav = "";
    switch (location.pathname) {
      case paths.customer.root:
        currentNav = "고객센터";
        break;
      case "/product/hot":
        currentNav = "HOT";
        break;
      case "/product/africatv":
        currentNav = "아프리카TV";
        break;
      case "/product/giftcard":
        currentNav = "상품권";
        break;
      case "/product/departmentstore":
        currentNav = "백화점";
        break;
      case "/product/discount":
        currentNav = "할인상품";
        break;
      default:
        currentNav = location.pathname;
        break;
    }
    setNav(currentNav);
  }, [location.pathname]);

  useEffect(() => {
    setCheckOutList(checkout)
  }, [checkout]);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('profile');
    router.replace('/login')
  }

  const handleLogin = () => {
    router.replace('/login');
  }

  const handleClick = (destination: string) => {
    startTransition(() => {
      setNav(destination);
      let routePath = "";
      switch (destination) {
        case "HOT":
          routePath = "/";
          break;
        case "아프리카TV":
          routePath = "/product/africatv";
          break;
        case "상품권":
          routePath = "/product/giftcard";
          break;
        case "백화점":
          routePath = "/product/departmentstore";
          break;
        case "할인상품":
          routePath = "/product/discount";
          break;
        case "고객센터":
          routePath = paths.customer.root;
          break;
        default:
          // 기본적으로는 어떤 동작도 수행하지 않음
          return;
      }
      // 라우터 이동
      console.log(routePath);
      router.push(routePath);
    });
  };

  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              display: "flex",
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Box
              component="img"
              src={logo}
              width={100}
              sx={{ cursor: "pointer" }}
              onClick={() => router.push("/")}
            />
          </Typography>

          {/*<Typography*/}
          {/*    variant="h5"*/}
          {/*    noWrap*/}
          {/*    component="a"*/}
          {/*    href="#app-bar-with-responsive-menu"*/}
          {/*    sx={{*/}
          {/*        mr: 2,*/}
          {/*        display: { xs: 'flex', md: 'none' },*/}
          {/*        flexGrow: 1,*/}
          {/*        fontFamily: 'monospace',*/}
          {/*        fontWeight: 700,*/}
          {/*        letterSpacing: '.3rem',*/}
          {/*        color: 'inherit',*/}
          {/*        textDecoration: 'none',*/}
          {/*    }}*/}
          {/*>*/}
          {/*    <Box component="img" src={logo} width={100}></Box>*/}
          {/*</Typography>*/}

          <Box
            gap={2}
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >


            {profileState ? (
                <>
                  <Typography variant="body1">{data?.name} 님 반가워요 :)</Typography>
                  <Button
                      key={"logout"}
                      sx={{ my: 2, display: "flex", color: "black" }}
                  >
                    <Typography variant="body1" onClick={handleLogout}>로그아웃</Typography>
                    <Iconify icon={"iconamoon:exit"} />
                  </Button>
                  <Tooltip title="Open settings">
                    <Button
                        key={"mypage"}
                        onClick={handleOpenUserMenu}
                        sx={{ my: 2, display: "flex", color: "black" }}
                    >
                      <Typography variant="body1">마이페이지</Typography>
                      <Iconify icon={"iconamoon:arrow-down-2-light"} />
                    </Button>
                  </Tooltip>
                  <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                        <MenuItem
                            key={setting.title}
                            onClick={() => {
                              router.push(setting.path);
                            }}
                        >
                          <Typography variant="body1" textAlign="center">
                            {setting.title}
                          </Typography>
                        </MenuItem>
                    ))}
                  </Menu>
                </>


            ) : (
                <>
                  <Button
                      key={"login"}
                      sx={{ my: 2, display: "flex", color: "black" }}
                  >
                    <Iconify icon={"iconamoon:exit"} />
                    <Typography variant="body1" onClick={handleLogin}>로그인</Typography>
                  </Button>
                  <Button
                      key={"register"}
                      sx={{ my: 2, display: "flex", color: "black" }}
                  >
                    <Typography
                        variant="body1" onClick={handleLogin}>회원가입</Typography>
                  </Button>

                </>
            )}
          </Box>
          {/*<Box sx={{ flexGrow: 0, mr:1}}*/}
          {/*     onClick={() => {router.push('/cart')}}*/}
          {/*>*/}
          {/*    <BadgeIcon icon={'fluent:person-24-filled'} invisible={true} sx={{backgroundColor: '#E5E5E5'}}/>*/}

          {/*</Box>*/}
          <Box
            sx={{ flexGrow: 0 }}
            onClick={() => {
              router.push("/cart");
            }}
          >
            <BadgeIcon icon={"ic:outline-shopping-cart"} invisible={false} count={checkout?.length}/>
          </Box>
        </Toolbar>
      </Container>
      <Divider />
      <Stack direction="row" spacing={1} sx={{ marginTop: 3, marginBottom: 2 }}>
        <NavIcon
          path={menu_1}
          title="HOT"
          onClick={() => handleClick("HOT")}
          current={nav}
        />
        <NavIcon
          path={menu_2}
          title="아프리카TV"
          onClick={() => handleClick("아프리카TV")}
          current={nav}
        />
        <NavIcon
          path={menu_3}
          title="상품권"
          onClick={() => handleClick("상품권")}
          current={nav}
        />
        <NavIcon
          path={menu_4}
          title="백화점"
          onClick={() => handleClick("백화점")}
          current={nav}
        />
        <NavIcon
          path={menu_5}
          title="할인상품"
          onClick={() => handleClick("할인상품")}
          current={nav}
        />
        <NavIcon
          path={menu_6}
          title="고객센터"
          onClick={() => handleClick("고객센터")}
          current={nav}
        />
      </Stack>
    </AppBar>
  );
}
