import React from "react";
import "./App.css";
import Router from "./routes/sections";
import ThemeProvider from "./theme";
// import { CheckoutProvider } from "./sections/checkout/context/checkout-provider";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

function App() {

    const queryClient = new QueryClient();

    return (
      <QueryClientProvider client={queryClient}>
          <ThemeProvider>
              {/*<CheckoutProvider>*/}
                  <Router />
              {/*</CheckoutProvider>*/}
          </ThemeProvider>
      </QueryClientProvider>

  );
}

export default App;
