import { lazy } from "react";
import { paths } from "src/routes/paths";
import CAClassicLayout from "../../layouts/customer/classic";
import MainLayout from "../../layouts/main";

export const MyPage = lazy(() => import("src/pages/mypage/mypage"));
export const OrderPage = lazy(() => import("src/pages/mypage/order"));

export const myPageRoutes = [
  {
    path: "/mypage",
    element: (
      <MainLayout>
        <CAClassicLayout title={"마이페이지"}>
          <MyPage />
        </CAClassicLayout>
      </MainLayout>
    ),
  },
  {
    path: "/order",
    element: (
      <MainLayout>
        <CAClassicLayout title={"마이페이지"}>
          <OrderPage />
        </CAClassicLayout>
      </MainLayout>
    ),
  },
];
