import * as React from "react";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Iconify from "../iconify";
import { SxProps } from "@mui/material/styles";

const shapeStyles = {
  bgcolor: "primary.main",
  width: 44,
  height: 44,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  cursor: "pointer",
};
const shapeCircleStyles = { borderRadius: "50%" };

export default function BadgeIcon({
  icon,
  invisible,
  count,
  sx,
}: {
  icon: string;
  invisible: boolean;
  count: number | undefined;
  sx?: SxProps;
}) {
  return (
    <Badge overlap="circular" badgeContent={count ?? 0} max={10} invisible={invisible}>
      <Box
        component="span"
        sx={{ ...shapeStyles, ...shapeCircleStyles, ...sx }}
      >
        <Iconify icon={icon} sx={{ height: "30px", width: "30px" }} />
      </Box>
    </Badge>
  );
}
