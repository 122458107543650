import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { PC, Mobile } from "../../hooks/use-MediaQuery";
import {useRouter} from '../../routes/hooks';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function CAClassicLayout({ children, title }: Props) {
    const router = useRouter();

  const breadcrumbs = [
      <Typography variant="body2" key="3" color="text.primary" onClick={() => router.push('/')}>
        홈
      </Typography>,
    <Typography variant="body2" key="3" color="text.primary">
      {title}
    </Typography>,
  ];

  const renderHead = (
    <Stack sx={{ mb: 1 }}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );

  return (
    <>
      <PC>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            p: "40px",
            width: "100%",
          }}
        >
          {renderHead}
          {children}
        </Box>
      </PC>
      <Mobile>{children}</Mobile>
    </>
  );
}
