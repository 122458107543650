import { Theme } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// ----------------------------------------------------------------------

export function defaultProps(theme: Theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckCircleOutlineIcon />,
        checkedIcon: <CheckCircleIcon />,
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: theme.palette.grey[300],
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          fontSize: "14px",
          color: theme.palette.grey[500],
          fontWeight: 500,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[200],
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          "& .MuiBadge-badge": {
            color: "white",
            backgroundColor: theme.palette.grey[500],
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          flexDirection: "column !important",
          width: "100px",
          borderRadius: "8px",
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
