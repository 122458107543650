import merge from 'lodash/merge';

import { Theme } from '@mui/material/styles';

import { loadingButton } from './components/loading-button';
import {textField} from './components/textfield';
import {card} from './components/card';
import {cssBaseline} from './components/css-baseline';
import {typography} from './components/typography';
import {checkbox} from './components/checkbox';
import {defaultProps} from './default-props';
import {dialog} from './components/dialog';
import {appbar} from './components/appbar';
import {table} from './components/table';
import {button} from './components/button';
import {chip} from './components/chip';
import {toggleButton} from './components/toggle-button';

// ----------------------------------------------------------------------

export function componentsOverrides(theme: Theme) {
    const components = merge(
        defaultProps(theme),
        loadingButton(theme),
        textField(theme),
        card(theme),
        cssBaseline(theme),
        typography(theme),
        checkbox(theme),
        dialog(theme),
        appbar(theme),
        table(theme),
        button(theme),
        chip(theme),
        toggleButton(theme),
    );

    return components;
}
