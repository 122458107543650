import {useMutation, useQuery} from "@tanstack/react-query";
import {
    decreaseCheckOut,
    deleteAllCheckout,
    deleteCheckout,
    deleteOutOfStock,
    getCheckoutList,
    increaseCheckOut
} from "./url";
import {UpdateCheckoutPayload} from "./interface";


export const useGetCheckOutList = () => {
    return useQuery({queryKey: ['checkout-list'], queryFn: getCheckoutList});
}

export const useDeleteAllCheckout = () =>
    useMutation({
        mutationKey: ['checkout-all-delete'], mutationFn: deleteAllCheckout
    });

export const useDeleteCheckout = () =>
    useMutation({
        mutationKey: ['checkout-delete'], mutationFn: deleteCheckout
    });

export const useDeleteOutOfStock = () =>
    useMutation({
        mutationKey: ['delete-outOfStock'], mutationFn: deleteOutOfStock
    });

export const useIncreaseCheckOut = () =>
    useMutation({
        mutationKey: ['increase-checkout'],
        mutationFn: (data: UpdateCheckoutPayload) => increaseCheckOut(data),
    });

// decreaseCheckOut 함수가 { id: number, amount: number } 형식을 받을 수 있도록 수정합니다.
export const useDecreaseCheckOut = () =>
    useMutation({
        mutationKey: ['decrease-checkout'],
        mutationFn: (data: UpdateCheckoutPayload) => decreaseCheckOut(data),
    });