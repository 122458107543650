import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export function typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          fontSize: "20px",
        },
        subtitle2: {
          fontSize: "18px",
        },
        body1: {
          fontSize: "16px",
        },
        body2: {
          fontSize: "14px",
        },
        caption: {
          fontSize: "12px",
        },

        h1: {
          fontSize: "32px",
          fontWeight: 700,
        },
        h2: {
          fontSize: "24px",
          fontWeight: 700,
        },
        h3: {
          fontSize: "20px",
          fontWeight: 700,
        },
        h4: {
          fontSize: "18px",
          fontWeight: 700,
        },
        h5: {
          fontSize: "16px",
          fontWeight: 700,
        },
        h6: {
          fontSize: "14px",
          fontWeight: 700,
        },

        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
