import { lazy } from "react";
import MainLayout from "../../layouts/main";
import CAClassicLayout from "../../layouts/customer/classic";
import { InquiryDetailPage, InquiryPage } from "./customer";
import { Outlet } from "react-router-dom";
export const ProductDetailsPage = lazy(
  () => import("src/pages/product/product-detail"),
);
export const AfricaPage = lazy(() => import("src/pages/africa"));
export const GiftCardPage = lazy(() => import("src/pages/giftcard"));
export const DepartmentPage = lazy(() => import("src/pages/department"));
export const SalePage = lazy(() => import("src/pages/sale"));
export const HomePage = lazy(() => import("src/pages/home"));
export const HotPage = lazy(() => import("src/pages/hot"));

export const productRoutes = [
  {
    path: "/product",
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [
      {
        path: "africatv",
        element: <AfricaPage />,
        children: [{ path: ":id", element: <ProductDetailsPage /> }],
      },
      { path: "giftcard", element: <GiftCardPage /> },
      { path: "departmentstore", element: <DepartmentPage /> },
      { path: "discount", element: <SalePage /> },
      { path: "hot", element: <HotPage /> },
      { path: ":id", element: <ProductDetailsPage /> },
    ],
  },
];
