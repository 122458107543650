import { lazy } from "react";
import AuthClassicLayout from "../../layouts/auth/classic";
import { paths } from "src/routes/paths";
import CAClassicLayout from "../../layouts/customer/classic";
import { Outlet } from "react-router-dom";
import MainLayout from "../../layouts/main";

export const CustomerPage = lazy(() => import("src/pages/customer/ca"));
export const InquiryPage = lazy(() => import("src/pages/customer/inquiry"));
export const PurchasePage = lazy(() => import("src/pages/customer/purchase"));
export const InquiryDetailPage = lazy(
  () => import("src/pages/customer/details"),
);
export const customerRoutes = [
  {
    path: "/customer",
    element: (
      <MainLayout>
        <CAClassicLayout title={"고객센터"}>
          <CustomerPage />
        </CAClassicLayout>
      </MainLayout>
    ),
  },
  {
    path: "inquiry",
    element: (
      <MainLayout>
        <CAClassicLayout title={"고객센터"}>
          <Outlet />
        </CAClassicLayout>
      </MainLayout>
    ),
    children: [
      { index: true, element: <InquiryPage /> },
      { path: ":id", element: <InquiryDetailPage /> },
    ],
  },
  {
    path: "/purchase",
    element: (
      <MainLayout>
        <CAClassicLayout title={"고객센터"}>
          <PurchasePage />
        </CAClassicLayout>
      </MainLayout>
    ),
  },
];
