import {IOrder, IUser} from './interface';
import { http } from "../../utils/http";

export const getMyAccountInfo = (): Promise<IUser> => {
    return http.get('/account-info');
}

export const getMyOrderList = (): Promise<IOrder[]> => {
    return http.get('/orders');
}

