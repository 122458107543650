import {http} from "../../utils/http";
import {ICheckout} from "./interface";
import {AxiosResponse} from "axios";

export const getCheckoutList = (): Promise<ICheckout[]> => {
    return http.get('/inventory')
}

export const deleteAllCheckout = () : Promise<AxiosResponse> => {
    return http.delete('/inventory/all')
}

export const deleteCheckout = () : Promise<AxiosResponse> => {
    return http.delete('/inventory')
}

export const deleteOutOfStock = () : Promise<AxiosResponse> => {
    return http.delete('/inventory/out-of-stock')
}

export const increaseCheckOut = ({ id, amount }: { id: number, amount: number }): Promise<AxiosResponse> => {
    return http.put(`/inventory/${id}/increase`, { amount });
}

export const decreaseCheckOut = ({ id, amount }: { id: number, amount: number }): Promise<AxiosResponse> => {
    return http.put(`/inventory/${id}/decrease`, { amount });
}