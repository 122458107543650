import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export function appbar(theme: Theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: `${theme.palette.grey[500]}`,
        },
      },
    },
  };
}
