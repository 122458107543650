import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export function loadingButton(theme: Theme) {
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main,
          height: "48px",
          fontWeight: 700,
          transition: "background-color 0.3s",
          borderRadius: "12px",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
  };
}
