import Header from "./header";
import Footer from "./footer";
import MobileFooter from "./mobile/mobile-footer";
import { Mobile, PC } from "../../hooks/use-MediaQuery";
import MobileHeader from "./mobile/mobile-header";

type Props = {
  children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
  return (
    <>
      <PC>
        <Header />
        {children}
        <Footer />
      </PC>
      <Mobile>
        <MobileHeader />
        {children}
        <MobileFooter />
      </Mobile>
    </>
  );
}
