import MainLayout from "../../layouts/main";
import CAClassicLayout from "../../layouts/customer/classic";
import { lazy } from "react";
import { Outlet } from "react-router-dom";

export const CartPage = lazy(() => import("src/pages/cart/cart"));
export const CartPayPage = lazy(() => import("src/pages/cart/cart-pay"));

export const cartRoutes = [
  {
    path: "cart",
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [
      {
        index: true,
        element: (
          <CAClassicLayout title={"장바구니"}>
            <CartPage />
          </CAClassicLayout>
        ),
      },
      {
        path: "pay",
        element: (
          <CAClassicLayout title={"장바구니 주문하기"}>
            <CartPayPage />
          </CAClassicLayout>
        ),
      },
    ],
  },
];
