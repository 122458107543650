const ROOTS = {
  // 추가
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  FIND: "/find",
  TERMS: "/register/terms",
  HOT: "/hot", // Add '/' to the beginning to make it an absolute path
  AFRICA: "/africatv", // Add '/' to the beginning to make it an absolute path
  GIFTCARD: "/giftcard", // Add '/' to the beginning to make it an absolute path
  DEPARTMENT: "/departmentstore", // Add '/' to the beginning to make it an absolute path
  DISCOUNT: "/discount", // Add '/' to the beginning to make it an absolute path
  CUSTOMER: "/customer", // Add '/' to the beginning to make it an absolute path
  INQUIRY: "/inquiry",
  MYPAGE: "/mypage",
  CART: "/cart",
  PRODUCT: "/product",
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: ROOTS.LOGIN,
      register: ROOTS.REGISTER,
      terms: ROOTS.TERMS,
      find: ROOTS.FIND,
    },
  },
  // DASHBOARD
  home: {
    root: ROOTS.HOME,
  },

  giftcard: ROOTS.GIFTCARD,
  department: ROOTS.DEPARTMENT,

  customer: {
    root: ROOTS.CUSTOMER,
  },
  inquiry: {
    root: ROOTS.INQUIRY,
    details: (id: number) => `${ROOTS.INQUIRY}/${id}`,
  },
  mypage: ROOTS.MYPAGE,
  product: {
    hot: ROOTS.HOT,
    africa: ROOTS.AFRICA,
    giftcard: ROOTS.GIFTCARD,
    department: ROOTS.DEPARTMENT,
    discount: ROOTS.DISCOUNT,
    details: (id: number) => `${ROOTS.PRODUCT}/${id}`,
  },
  cart: {
    root: ROOTS.CART,
    pay: `${ROOTS.CART}/pay`,
  }
};
