import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Badge, Divider } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import logo from "src/logo/logo.png";
import { useLocation } from "react-router-dom";
import BadgeIcon from "../../../components/badge/badge";
import { useRouter } from "../../../routes/hooks";
import {useGetCheckOutList} from "../../../apis/checkout/quries";

const pages = ["로그아웃", "마이페이지"];
const settings = [
  { title: "주문조회", path: "/order" },
  { title: "내 정보", path: "/mypage" },
];

export default function MobileHeader() {
  const router = useRouter();
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const {error : checkoutError, data : checkout} = useGetCheckOutList();

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              display: "flex",
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Box
              component="img"
              src={logo}
              width={100}
              onClick={() => router.push("/")}
            />
          </Typography>

          {/*<Typography*/}
          {/*    variant="h5"*/}
          {/*    noWrap*/}
          {/*    component="a"*/}
          {/*    href="#app-bar-with-responsive-menu"*/}
          {/*    sx={{*/}
          {/*        mr: 2,*/}
          {/*        display: { xs: 'flex', md: 'none' },*/}
          {/*        flexGrow: 1,*/}
          {/*        fontFamily: 'monospace',*/}
          {/*        fontWeight: 700,*/}
          {/*        letterSpacing: '.3rem',*/}
          {/*        color: 'inherit',*/}
          {/*        textDecoration: 'none',*/}
          {/*    }}*/}
          {/*>*/}
          {/*    <Box component="img" src={logo} width={100}></Box>*/}
          {/*</Typography>*/}

          <Box
            gap={2}
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="Open settings">
              <Button
                key={"mypage"}
                onClick={handleOpenUserMenu}
                sx={{ my: 2, display: "flex", color: "black" }}
              >
                <Box sx={{ flexGrow: 0, mr: 1 }}>
                  <BadgeIcon
                    count={checkout?.length}
                    icon={"fluent:person-24-filled"}
                    invisible={true}
                    sx={{ backgroundColor: "#E5E5E5" }}
                  />
                </Box>
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.title}
                  onClick={() => {
                    router.push(setting.path);
                  }}
                >
                  <Typography variant="body1" textAlign="center">
                    {setting.title}
                  </Typography>
                </MenuItem>
              ))}
              <MenuItem>
                <Typography variant="body1" textAlign="center">
                  로그아웃
                </Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box
            sx={{ flexGrow: 0 }}
            onClick={() => {
              router.push("/cart");
            }}
          >
            <BadgeIcon icon={"ic:outline-shopping-cart"} invisible={false} count={checkout?.length}/>
          </Box>
        </Toolbar>
      </Container>
      <Divider />
    </AppBar>
  );
}
