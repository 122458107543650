import { lazy } from "react";
import AuthClassicLayout from "../../layouts/auth/classic";
import { paths } from "src/routes/paths";

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import("src/pages/auth/jwt/login"));
const JwtRegisterPage = lazy(() => import("src/pages/auth/jwt/register"));
const JwtFindPage = lazy(() => import("src/pages/auth/jwt/find"));
const JwtTermsPage = lazy(() => import("src/pages/auth/jwt/terms"));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path:  paths.auth.jwt.login,
    element: (
      <AuthClassicLayout>
        <JwtLoginPage />
      </AuthClassicLayout>
    ),
  },
  {
    path: paths.auth.jwt.register,
    element: <JwtRegisterPage />,
  },
  {
    path: paths.auth.jwt.find,
    element: (
      <AuthClassicLayout>
        <JwtFindPage />
      </AuthClassicLayout>
    ),
  },
  {
    path: paths.auth.jwt.terms,
    element: <JwtTermsPage />,
  },

];
