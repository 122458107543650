import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import logo from "src/logo/logo.png";
import { Mobile, PC } from "../../hooks/use-MediaQuery";
import MobileFooter from "../main/mobile/mobile-footer";
import Footer from "../main/footer";
import { useRouter } from "../../routes/hooks";

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children }: Props) {
  const router = useRouter();

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, mt: "20%", textAlign: "center" }}>
      <Box
        component="img"
        src={logo}
        sx={{ width: { xs: 100, lg: 170 } }}
        onClick={() => router.push("/")}
      />
    </Stack>
  );

  const renderNav = (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
      sx={{ mt: 2, mb: "20%", cursor:'pointer' }}

    >
        <Typography variant='body2' onClick={() => router.push('/')}>메인으로</Typography>
        <Typography variant='body2' onClick={() => router.push('/find')}>아이디/비밀번호 찾기</Typography>
        <Typography variant='body2' onClick={() => router.push('/register/terms')}>회원가입</Typography>
    </Stack>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {renderHead}
        {children}
        {renderNav}
      </Box>
      <PC>
        <Footer />
      </PC>
      <Mobile>
        <MobileFooter />
      </Mobile>
    </>
  );
}
