import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export function card(theme: Theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: "relative",
          borderRadius: "12px",
          textAlign: "left",
          zIndex: 0,
          padding: "28px",
          border: "none",
          boxShadow: "none",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {},
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
