import { atom } from "recoil";
import {IInquiry} from "../apis/inquiry/interface";
import {ICheckout} from "../apis/checkout/interface";

export const SelectedInquiryState = atom<IInquiry | undefined>({
    key: "SelectedInquiry",
    default: undefined,
});

export const CheckoutListState = atom<ICheckout[] | undefined>({
    key: "CheckoutList",
    default: undefined,
});

export const CheckoutState = atom<ICheckout | undefined>({
    key: "Checkout",
    default: undefined,
});
