import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export function toggleButton(theme: Theme) {
  return {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: "#EFEFEF",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[400],
          fontWeight: 700,
          border: "none",
          width: "50%",
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };
}
