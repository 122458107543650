import { alpha } from "@mui/material/styles";

export type ColorSchema = 'grey' | 'main' | 'warning' | 'info' | 'common' | 'success' | 'primary';


export const grey = {
    0: '#FFFFFF',
    100: '#F5F5F5',
    200: '#C6C6CD',
    300: '#8C8B94',
    400: '#5F5F64',
    500: '#2E2E32',
    600: '#0F0F10',
}

export const primary = {
    main: '#F3874A',
    darker: '#EC594F',
    dark: '#EC594F',
    lighter: 'white'
}

export const error = {
    main: '#EC594F',
    darker: '#EC594F',
    dart: '#EC594F'
}

export const info = {
    main: '#EC594F',
    darker: '#EC594F',
    dart: '#EC594F',
    light: '#FFFFFF',
}

export const warning = {
    main: '#EC594F',
    darker: '#EC594F',
    dart: '#EC594F',

}

export const success = {
    main: '#EC594F',
    darker: '#EC594F',
    dart: '#EC594F',}
export const secondary = {
    main: '#EC594F',
    darker: '#EC594F',
    dart: '#EC594F',}

export const common = {
    main: '#EC594F',
    black: 'black',
}


export const action = {
    hover: alpha(grey[500], 0.08),
    selected: alpha(grey[500], 0.16),
    disabled: grey[600],
    disabledBorder: alpha(grey[500], 0.2),
    disabledBackground: alpha(grey[500], 0.08),
    focus: alpha(grey[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
};

const base = {
    grey,
   primary,
    error,
    action,
    secondary,
    warning,
    info,
    common
};


export function palette() {
    const theme = {
        ...base,
        text: {
            primary: grey[500],
            secondary: grey[600],
            disabled: grey[500],
        },
        background: {
            paper: '#FFFFFF',
            default: '#F5F5F5',
            neutral: grey[200],
        },
        action: {
            active: grey[600],
        },

    };

    return theme;
}
