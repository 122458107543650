import { Theme } from "@mui/material/styles";
import { CheckboxProps, checkboxClasses } from "@mui/material/Checkbox";

// ----------------------------------------------------------------------

export function checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: CheckboxProps }) => {
          return {
            padding: theme.spacing(1),
            [`&.${checkboxClasses.root}`]: {
              color: theme.palette.grey[300],
            },
            [`&.${checkboxClasses.checked}`]: {
              color: theme.palette.primary.main,
            },
          };
        },
      },
    },
  };
}
