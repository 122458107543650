import * as React from "react";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Iconify from "../iconify";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

interface NavIconProps {
  path: string;
  title: string;
  current?: string;
  onClick: () => void;
}
export default function NavIcon({
  path,
  title,
  current,
  onClick,
}: NavIconProps) {
  const StyledBadge = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
      left: "50%",
    },
  }));

  return (
    <StyledBadge
      badgeContent={11}
      max={10}
      variant="dot"
      invisible={current !== title}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        width={80}
        sx={{
          position: "relative",
          ...(title === current && { top: "-10px" }),
        }}
      >
        <Box
          component="img"
          src={path}
          height={44}
          onClick={onClick}
          sx={{ cursor: "pointer" }}
        />
        <Typography variant="h6">{title}</Typography>
      </Stack>
    </StyledBadge>
  );
}
